<template>
  <v-container>
    <v-row justify="space-around">
      <TooltipIcon
        v-if="project.source"
        label="source"
        :destination="project.source"
        :icon="icon"
        location="top"
      />
      <TooltipIcon
        v-if="project.url"
        label="launch_app"
        :destination="project.url"
        :icon="mdiLaunch"
        location="top"
      />
      <!--      <TooltipIcon-->
      <!--        label="info"-->
      <!--        v-if="pageContext.urlParsed.pathname !== infoUrl"-->
      <!--        :destination="infoUrl"-->
      <!--        :icon="mdiInformation"-->
      <!--        location="top"-->
      <!--      />-->
    </v-row>
    <v-row v-if="project.tags">
      <TagGroup :tags="project.tags" />
    </v-row>
  </v-container>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import {
  mdiGitlab,
  mdiGithub,
  mdiSourceBranch,
  mdiLaunch,
  // mdiInformation,
} from "@mdi/js";
import { Project } from "@/types";
const props = defineProps<{
  project: Project;
}>();
// const infoUrl = computed(() => `/project/${props.project.id}`);
const icon = computed(() => {
  if (props.project.source.includes("github")) return mdiGithub;
  if (props.project.source.includes("gitlab")) return mdiGitlab;
  return mdiSourceBranch;
});
</script>
