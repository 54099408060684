<template>
  {{ translated }}
</template>
<script lang="ts" setup>
import translate from "@/services/translate";
import { usePageContext } from "vike-vue/usePageContext";
import { computed } from "vue";
import { Translatable } from "@/types";
const props = defineProps<{
  item: Translatable;
}>();
const translated = computed(() => {
  return translate(usePageContext())(props.item);
});
</script>
