<template>
  <v-tooltip :text="translate(label)" :location="location">
    <template #activator="{ props }">
      <v-btn
        variant="elevated"
        :href="destination"
        :icon="icon"
        v-bind="props"
      />
    </template>
  </v-tooltip>
</template>
<script lang="ts" setup>
import translateRaw from "@/services/translate";
import { usePageContext } from "vike-vue/usePageContext";
const translate = translateRaw(usePageContext());
defineProps<{
  label: string;
  destination: string;
  icon: string;
  location: "top" | "bottom"; //There are lots more, but I don't know how to link them directly.
}>();
</script>
