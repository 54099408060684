<template>
  {{ formatted }}
</template>
<script lang="ts" setup>
import { DateType } from "@/types";
import { computed } from "vue";
const props = defineProps<{
  date: DateType;
}>();
const formatted = computed(() => {
  let date = props.date;
  if (typeof date == "number") return date.toString();
  if (typeof date == "string") {
    try {
      date = new Date(date);
    } catch (_) {
      return date;
    }
  }
  return date.toLocaleDateString();
});
</script>
