<template>
  <v-timeline
    ref="timeline"
    :direction="xs ? 'vertical' : 'horizontal'"
    :side="singleSide ? 'end' : undefined"
    style="overflow-x: auto"
  >
    <v-timeline-item
      v-for="project in projects"
      :key="project.id"
      :dotColor="
        project.dates.lastUpdated
          ? 'blue'
          : project.dates.finished
            ? 'green'
            : 'yellow'
      "
    >
      <TimeFormatted
        v-if="xs"
        :date="
          project.dates.lastUpdated ??
          project.dates.finished ??
          project.dates.started
        "
      />
      <template v-if="!xs" #opposite>
        <TimeFormatted
          :date="
            project.dates.lastUpdated ??
            project.dates.finished ??
            project.dates.started
          "
        />
      </template>
      <v-responsive>
        <SmallCard :project="project" :forceWrap="!xs" />
      </v-responsive>
    </v-timeline-item>
  </v-timeline>
</template>
<script lang="ts" setup>
import { useDisplay } from "vuetify";
import { TransformedProject } from "@/types";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
const { xs } = useDisplay();
defineProps<{
  projects: TransformedProject[];
}>();
const checkingLarge = ref(false);
const largeCompatible = ref(true);
const resizeTimeout = ref(
  setTimeout(() => {}) as ReturnType<typeof setTimeout>,
);
const singleSide = computed(() => {
  if (xs.value) return true;
  if (checkingLarge.value) return false;
  if (largeCompatible.value) return false;
  return true;
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});
function handleResize() {
  clearTimeout(resizeTimeout.value);
  resizeTimeout.value = setTimeout(() => {
    if (checkingLarge.value) return;
    checkingLarge.value = true;
    setTimeout(() => {
      largeCompatible.value =
        document.scrollingElement!.clientHeight >=
        document.scrollingElement!.scrollHeight;
      checkingLarge.value = false;
    }, 1);
  }, 100);
}
</script>
