<template>
  <v-card>
    <CustomLink :to="to">
      <template v-if="project.images?.thumbnail">
        <template v-if="typeof project.images.thumbnail === 'string'">
          <noscript v-html="noscript" />
          <v-no-ssr>
            <v-img
              class="ma-2"
              style="aspect-ratio: 1 / 1"
              :src="project.images.thumbnail"
            />
          </v-no-ssr>
        </template>
        <svg v-else viewBox="0 0 24 24" class="ma-2">
          <path
            :d="project.images.thumbnail.icon"
            :fill="project.images.thumbnail.color || 'darkgray'"
          />
        </svg>
      </template>
      <svg v-else viewBox="0 0 24 24" class="ma-2">
        <path :d="mdiImageOff" fill="gray" />
      </svg>
    </CustomLink>
    <v-card-title
      :style="
        forceWrap
          ? 'white-space: unset !important; width: min-content; hyphens: unset !important;'
          : undefined
      "
    >
      <Translation :item="project.name" />
    </v-card-title>
    <v-card-actions v-if="actionsEnabled">
      <Actions :project="project" />
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { mdiImageOff } from "@mdi/js";
import { computed } from "vue";
import { TransformedProject } from "@/types";
import { usePageContext } from "vike-vue/usePageContext";
const props = defineProps<{
  project: TransformedProject;
  actionsEnabled: boolean;
  forceWrap: boolean;
}>();
const projectPage = computed(() => {
  return "/project/" + props.project.id;
});
const pageContext = usePageContext();
const to = computed(() => {
  if (pageContext.urlParsed.pathname != projectPage.value)
    return projectPage.value;
  if (props.project.url) return props.project.url;
  if (props.project.source) return props.project.source;
  return null;
});
const noscript = computed(() => {
  if (!props.project.images?.thumbnail) return "";
  if (typeof props.project.images.thumbnail != "string") return "";
  return `<img src="${props.project.images.thumbnail.replaceAll('"', "&quot;")}" style="aspect-ratio: 1 / 1; width: 100%" class="ma-2" />`;
});
</script>
