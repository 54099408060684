<template>
  <v-container class="pa-sm-10" fluid>
    <div class="d-flex">
      <v-btn-toggle :value="tagsVisible">
        <v-btn :value="true" :icon="mdiTag" :href="tagsToggleHref" />
      </v-btn-toggle>
      <v-no-ssr>
        <v-text-field
          v-model="search"
          class="flex-grow-1 mx-sm-10"
          hideDetails="auto"
          :label="translate('search')"
          singleLine
          density="comfortable"
        />
        <v-btn-toggle v-model="view" mandatory>
          <v-btn :icon="mdiGrid" value="grid" />
          <v-btn :icon="mdiTimeline" value="timeline" />
        </v-btn-toggle>
      </v-no-ssr>
    </div>
    <TagGroup v-if="tagsVisible" :tags="tags" />
    <template v-if="filtered.length > 0">
      <GridView v-if="view === 'grid'" :projects="filtered" />
      <Timeline v-else-if="view === 'timeline'" :projects="filtered" />
    </template>
    <Translation v-else item="no_projects_found" />
  </v-container>
</template>
<script setup lang="ts">
import GridView from "@/listViews/GridView.vue";
import Timeline from "@/listViews/Timeline.vue";
import { mdiGrid, mdiTag, mdiTimeline } from "@mdi/js";
import translateRaw from "@/services/translate";
import { usePageContext } from "vike-vue/usePageContext";
const translate = translateRaw(usePageContext());
import { TransformedProject } from "@/types";
import { filter } from "@/services/data";
import { computed, ref, watch } from "vue";
import { tags } from "@/services/data";
const search = ref("");
const view = ref("timeline"); //localStorage.getItem("view") || ("timeline" as "grid" | "timeline");
const props = defineProps<{
  tag?: string;
}>();
const tagsVisible = ref(props.tag !== undefined);

const filtered = computed(() => {
  return filter(
    (project: TransformedProject) =>
      [
        translate(project.name),
        translate(project.description),
        project.tags?.map((tag) => tag.name) || [],
        "",
      ]
        .flat()
        .join()
        .toLowerCase()
        .includes(search.value.toLowerCase()) &&
      (!props.tag ||
        (!!project.tags &&
          project.tags.map((tag) => tag.id).includes(props.tag))),
  );
});
watch(view, (newValue: string) => {
  console.log(newValue);
  // localStorage.setItem("view", newValue);
});
const tagsToggleHref = computed(() => {
  if (props.tag) return "/tag";
  if (tagsVisible.value) return "/";
  return "/tag";
});
</script>
