// @ts-expect-error yml parsing is not typed :(
import data from "/data.yml";
import lastUpdated from "../../generated/lastUpdated";
import {
  DateType,
  FileData,
  Tag,
  TransformedProject,
  Translatable,
} from "@/types";
import generatedIcon from "../../generated/icons";
import showdown from "showdown";
const converter = new showdown.Converter();
converter.setFlavor("github");

const typed = data as FileData;
Object.entries(lastUpdated).forEach(([id, date]) => {
  const project = typed.items.find((project) => project.id == id)!;
  if (project) project.dates.lastUpdated = date;
});
function getDate(item: {
  dates: { started: string; finished?: string; lastUpdated?: string };
}): number {
  const date: DateType =
    item.dates.lastUpdated ?? item.dates.finished ?? item.dates.started;
  if (!date) throw new Error("No date found!");
  if (typeof date == "number") return new Date(`${date}-01-01`).getTime();
  if (typeof date == "object") return date.getTime();
  if (typeof date == "string") return new Date(date).getTime();
  throw new Error("Invalid date type");
}
typed.items = typed.items.sort((a, b) => getDate(a) - getDate(b));

typed.tags.forEach((tag) => {
  if (tag.icon && tag.icon.startsWith("mdi"))
    tag.icon = generatedIcon(tag.icon);
  if (typeof tag.name == "string") tag.name = getTranslation(tag.name);
});

typed.items.forEach((project) => {
  const thumbnail = project.images?.thumbnail;
  if (typeof project.description == "string") {
    project.description = converter.makeHtml(project.description);
  } else {
    project.description = Object.fromEntries(
      Object.entries(project.description).map(([key, value]) => [
        key,
        converter.makeHtml(value),
      ]),
    ) as Translatable;
  }
  if (
    thumbnail &&
    typeof thumbnail != "string" &&
    "icon" in thumbnail &&
    thumbnail.icon.startsWith("mdi")
  ) {
    // @ts-expect-error everything here is safe.
    project.images.thumbnail.icon = generatedIcon(thumbnail.icon);
  }
  const transformedProject = project as TransformedProject;
  if (project.tags) transformedProject.tags = project.tags.map(getTag);
  if (typeof project.name == "string")
    project.name = getTranslation(project.name);
  if (typeof project.description == "string")
    project.description = getTranslation(project.description);
});

export default typed as FileData;

function getTranslation(text: string) {
  return typed.translations[text] || text;
}

function getTag(id: string | Tag) {
  if (typeof id === "object") return id;
  return typed.tags.find((tag) => tag.id == id)!;
}
